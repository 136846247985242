
import { api } from "@/api/api";
import { ApiGetGuestEmployeewithMemberOrganizationDto } from "@/api/generated/Api";
import TableFilter from "@/components/shell/TableFilter.vue";
import { hasMemberOrgAccess } from "@/shared/helpers/accessLevelApiAdapter";
import {
  MenuItem,
  adminItems,
  contactItems,
  courseItems,
  dashboardItems,
  studyplanItems,
  watchRoutePathImmediate,
} from "@/shared/helpers/shellHelpers";
import { useRouter, useStore } from "@/shared/useHelpers";
import { StoreState } from "@/store/store.state.interface";
import { defineComponent, onMounted, ref } from "@vue/composition-api";

// TODO Fragile handling of menu items which should be refactored, the menu
// item logic could be moved to beforeEnter hooks on parent routes instead
export default defineComponent({
  name: "TheDrawer",
  components: { TableFilter },
  setup() {
    const items = ref<MenuItem[]>([]);
    const router = useRouter();
    const store = useStore<StoreState>();
    const currentGuestUser = ref<ApiGetGuestEmployeewithMemberOrganizationDto>();

    const getCurrentUser = async () => {
      if (!hasMemberOrgAccess) {
        return;
      }

      currentGuestUser.value = (await api.guestside.getGuestUserCurrentGuestEmployeeAsync()).data;
    };

    onMounted(() => {
      getCurrentUser();
    });

    const updateMenuItems = async (routePath?: string) => {
      if (!routePath || routePath?.includes("/client-account-courses")) {
        items.value = [];
      }
      if (routePath?.includes("/kurs")) {
        items.value = (await courseItems(store, routePath, +router.currentRoute.params.id, router)) ?? [];
      }
      if (routePath?.includes("/studieplaner")) {
        items.value = studyplanItems(router);
      }
      if (routePath?.includes("/admin")) {
        items.value = adminItems(router);
      }
      if (routePath?.includes("/adressebok")) {
        items.value = contactItems(router);
      }
      //  Needs to be at the bottom!!!!!
      if (routePath?.includes("/home")) {
        if (!hasMemberOrgAccess) {
          // Sorry about this, I was pressed for time
          items.value = dashboardItems(router).filter(
            (x) => x.link !== "/home/preApproved" && x.link !== "/home/preApprovedAofCourses"
          );

          return;
        }

        currentGuestUser.value = (await api.guestside.getGuestUserCurrentGuestEmployeeAsync()).data;
        let memberOrganization;

        if (currentGuestUser.value.memberOrganization) {
          memberOrganization = await api.guestside
            .getMemberOrganizationFeatureSettings1(currentGuestUser.value.memberOrganization.id)
            .then((x) => x.data);
        }

        if (hasMemberOrgAccess && !memberOrganization?.usePreApprovalForCourses) {
          items.value = dashboardItems(router).filter(
            (x) => x.link !== "/home/preApproved" && x.link !== "/home/preApprovedAofCourses"
          );
          return;
        }

        items.value = dashboardItems(router);
      }
    };

    watchRoutePathImmediate(updateMenuItems);

    return { items };
  },
});
